<template>
  <div class="form-group mb-3">
    <div class="mb-2">
      <div class="row">
        <div class="col">
          <label
            v-bind:for="componentId + '-file'"
            class="custom-label"
            v-bind:class="{
              'text-danger': this.imageRequired && $v.data.files.$error,
            }"
            >{{ title }}</label
          >
        </div>
        <div class="col-auto" v-if="this.imageRequired && $v.data.files.$error">
          <div class="text-danger ml-auto" v-if="!$v.data.files.required">
            <small><i>Zorunlu Alan</i></small>
          </div>
        </div>
      </div>
      <div v-show="!hideDropzone">
        <input
          type="file"
          ref="fileInput"
          v-bind:id="componentId + '-file'"
          class="form-control d-none"
          v-on:change="selectFile()"
        />
        <div
          ref="dropzone"
          class="dropzone-area"
          v-bind:class="{
            'dropzone--error': this.imageRequired && $v.data.files.$error,
          }"
        >
          <i v-bind:class="icon"></i>
          <span class="dropdonze-header text-muted">{{ dragText }}</span>
          <span class="or-divider">{{ orText }}</span>
          <div class="text-center">
            <span
              class="btn btn-outline-primary"
              v-on:click="$refs.fileInput.click()"
              >{{ buttonText }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div
      v-show="!hideDropzone"
      class="text-danger font-14 my-2"
      v-html="sizeLimitMessage"
    ></div>
    <div v-if="data.files[0] && data.files[0].fileName" class="uploaded-card">
      <div class="uploaded-media card-body">
        <div class="row align-items-center mb-3">
          <div class="col text-truncate">
            <span class="d-block">{{ data.files[0].fileName }}</span>
            <span class="text-secondary">{{ data.files[0].fileSize }}</span>
          </div>
          <div class="col-auto">
            <span
              class="btn btn-outline-danger font-14"
              v-on:click="removeFile()"
            >
              <i class="fas fa-trash"></i>
            </span>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-12 col-md-auto text-center text-md-left mb-3 mb-md-0">
            <img
              v-if="data.files[0].isImage"
              class="rounded"
              width="120"
              v-bind:src="data.files[0].fileData"
              style="object-fit: contain"
            />
            <video
              v-else-if="data.files[0].isVideo"
              width="160"
              class="rounded"
              v-bind:src="data.files[0].fileData"
              ref="video"
            ></video>
          </div>
          <div v-if="metaVisible" class="col-12 col-md">
            <div class="d-block mb-2">
              <input
                type="text"
                class="font-14 form-control"
                placeholder="Görsel Alt Etiketi"
                v-model="data.files[0].metaAlt"
              />
            </div>
            <div class="d-block">
              <input
                type="text"
                class="font-14 form-control"
                placeholder="Görsel Başlığı"
                v-model="data.files[0].metaTitle"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "Single",
  validations() {
    if (this.imageRequired) {
      return {
        data: {
          files: {
            required: function () {
              return this.data.files.length > 0;
            },
            minLength: minLength(1),
          },
        },
      };
    } else {
      return {};
    }
  },
  props: {
    data: {
      files: [],
      filesData: null,
    },
    title: {
      default: "Görsel Yükle",
    },
    icon: {
      default: "fas fa-cloud-upload-alt fa-3x text-secondary",
    },
    buttonText: {
      default: "Dosya Seç",
    },
    dragText: {
      default: "Dosya Sürükle & Bırak",
    },
    orText: {
      default: "veya",
    },
    sizeLimit: {
      default: "1024",
    },
    sizeLimitMessage: {
      default: "En fazla <b>1024 KB</b> boyutunda dosya yükleyebilirsiniz.",
    },
    metaVisible: {
      default: "true",
    },
    hideDropzone: {
      default: false,
    },
    mimeBmp: {
      default: "",
    },
    mimeJpeg: {
      default: "true",
    },
    mimePng: {
      default: "true",
    },
    mimeGif: {
      default: "true",
    },
    mimeWebp: {
      default: "true",
    },
    mimeTiff: {
      default: "",
    },
    mimeSvg: {
      default: "",
    },
    mimeMp4: {
      default: "true",
    },
    mimeMpeg: {
      default: "true",
    },
    mimeOgg: {
      default: "true",
    },
    mimeWebm: {
      default: "true",
    },
    videoAutoPlay: {
      default: "true",
    },
    imageRequired: {
      default: "false",
    },
    fixedMetaTitle: {
      default: "",
    },
    fixedMetaAlt: {
      default: "",
    },
  },
  model: {
    prop: "data",
  },
  computed: {
    mimeTypes() {
      const result = [];

      helper.setMimeTypes(this._props, result);

      return result;
    },
  },
  methods: {
    clearFile() {
      this.data.files = [];
      this.data.filesData = null;
    },
    newFile() {
      this.clearFile();

      this.data.files.push({
        // id: "",
        metaAlt: this.fixedMetaTitle,
        metaTitle: this.fixedMetaAlt,
        fileName: "",
        fileSize: "",
        fileData: "",
        isImage: false,
        isVideo: false,
        isMain: 0,
        mimeType: "",
      });

      this.data.filesData = {};
    },
    selectFile() {
      const input = this.$refs.fileInput;

      if (input.files.length) {
        this.createFile(input.files[0]);
      }
    },
    createFile(file) {
      this.newFile();

      if (this.mimeTypes.indexOf(file.type) >= 0) {
        if (file.size <= parseInt(this.sizeLimit) * 1024) {
          this.data.files[0].fileName = file.name;
          this.data.files[0].fileSizeByte = file.size;
          this.data.files[0].fileSize = helper.formatSize(file.size);
          this.data.files[0].mimeType = file.type;
          this.data.files[0].isImage = helper.mimeTypeIsImage(file.type);
          this.data.files[0].isVideo = helper.mimeTypeIsVideo(file.type);

          const reader = new FileReader();
          reader.onload = (e) => {
            this.data.files[0].fileData = e.target.result;
            this.data.files[0].isMain = 1;
            this.data.files[0].file = file;

            if (this.data.files[0].isVideo && this.videoAutoPlay) {
              const video = this.$refs.video;
              video.setAttribute("autoplay", "true");
              video.setAttribute("loop", "true");
            }

            this.clearInput();
          };

          reader.readAsDataURL(file);
        } else {
          this.removeFile(
            "Yüklemeye çalıştığınız dosya boyutu yüksek!\n" +
              this.sizeLimitMessage
          );
        }
      } else {
        this.removeFile(
          "Yüklemeye çalıştığınız dosya türü geçersiz! <br> Desteklenen formatlar: <b>" +
            this.mimeTypes.join(" - ") +
            "<b>"
        );
      }
    },
    clearInput() {
      this.$refs.fileInput.value = "";
    },
    removeFile(message) {
      this.$parent.$emit("on-remove", this.data.files[0]);
      this.clearInput();
      this.clearFile();
      if (message) {
        this.$swal({
          title: "Hata",
          html: message,
          icon: "error",
        });
      }
    },
    dropzoneHighlight(unhighlight) {
      const dropzone = this.$refs.dropzone;

      if (!unhighlight) {
        dropzone.classList.add("highlight");
      } else {
        dropzone.classList.remove("highlight");
      }
    },
    onDragEnter(e) {
      e.preventDefault();
      e.stopPropagation();

      this.dropzoneHighlight();
    },
    onDragLeave(e) {
      e.preventDefault();
      e.stopPropagation();

      if (this.lastDragLeave + 1000 < Date.now()) {
        this.lastDragLeave = Date.now();
        this.dropzoneHighlight(true);
      }
    },
    onDragOver(e) {
      e.preventDefault();
      e.stopPropagation();

      this.dropzoneHighlight();
    },
    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();

      this.dropzoneHighlight(true);

      if (e.dataTransfer.files && e.dataTransfer.files.length) {
        this.createFile(e.dataTransfer.files[0]);
      }
    },
  },
  mounted() {
    this.lastDragLeave = Date.now();
    // this.clearFile();

    this.$refs.fileInput.setAttribute(
      "accept",
      helper.getAcceptedMimeTypes(this._props)
    );

    const dropzone = this.$refs.dropzone;
    dropzone.addEventListener("dragenter", this.onDragEnter, false);
    dropzone.addEventListener("dragleave", this.onDragLeave, false);
    dropzone.addEventListener("dragover", this.onDragOver, false);
    dropzone.addEventListener("drop", this.onDrop, false);
  },
  data() {
    return {
      componentId:
        "weimageuploader-single-" + helper.getRandomInteger(1, 999999),
      lastDragLeave: null,
    };
  },
};
</script>

<style lang="scss" scoped>
/* DROPDONZE AREA */
.dropzone-area {
  position: relative;
  padding: 1rem;
  border-radius: 6px;
  border: 2px dashed #1e90ff;
  background-color: #1e8fff15;
}

.dropzone-area.highlight {
  border-color: #2ed573;
  background-color: #2ed5742f;
}

.dropzone-area span,
.dropzone-area a {
  font-size: 14px;
}

.or-divider,
.dropdonze-header,
.dropzone-area > i {
  display: block;
  margin-bottom: 0.5rem;
  text-align: center;
}
.dropzone--error {
  border-color: #d5472e;
  background-color: #d5342e10;
}
</style>
